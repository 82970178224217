import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { useMediaQuery } from "@shared/lib/hooks/useMediaQuery.ts";
import { useUserImage, useUserStatus } from "@shared/lib/hooks/useUser.ts";
import { AvatarClient, Box, Button, Typography } from "@shared/ui";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import css from "./AvatarHeader.module.scss";

interface AvatarHeaderProps {
  label?: string;
  direction?: "row" | "row-reverse";
  hasButton?: boolean;
  onClose?: () => void;
}

const AvatarHeader = ({
  label,
  direction = "row",
  hasButton = false,
  onClose,
}: AvatarHeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isMobile = useMediaQuery("Mobile");

  const image = useUserImage();
  const status = useUserStatus();

  const toggleAvatar = () => {
    navigate(AppRoutes.PROFILE);
    if (onClose) {
      onClose();
    }
  };

  const renderLogo = () => {
    return (
      <Box direction={direction} alignItems="center" className="gap-[8px]">
        <AvatarClient
          onClick={toggleAvatar}
          type="withStatus"
          status={status}
          image={image}
          isHeaderAvatar
        />
        {label && (
          <Typography
            color="purple"
            variant="body2"
            fontWeight={isMobile ? "bold" : "normal"}
            className={css.role}
          >
            {label}
          </Typography>
        )}
      </Box>
    );
  };

  if (!hasButton) {
    return renderLogo();
  }

  return (
    <Box direction="row" justifyContent="between" className="mb-4">
      {renderLogo()}
      <Button onClick={toggleAvatar} className="max-w-[160px]" noPadding>
        {t("to-profile")}
      </Button>
    </Box>
  );
};

export default memo(AvatarHeader);
