import ManagerIcon from "@shared/assets/icons/dashboard/roles/manager.svg";
import SpecialistIcon from "@shared/assets/icons/dashboard/roles/specialist.svg";
import AdminIcon from "@shared/assets/icons/dashboard/roles/supervisor.svg";
import SupportIcon from "@shared/assets/icons/dashboard/roles/support.svg";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { useTranslation } from "react-i18next";

import { Box } from "../../../box";
import { OverflowTooltip } from "../../../tooltip";
import css from "./TableRole.module.scss";

interface TableStatus {
  type?: Exclude<AppRole, AppRole.SUPER_ADMIN | AppRole.SUPPORT>;
}

const roleVariantsIcon = {
  [AppRole.SUPER_ADMIN]: <AdminIcon />,
  [AppRole.SUPERVISOR]: <AdminIcon />,
  [AppRole.SUPPORT]: <SupportIcon />,
  [AppRole.DIRECTOR]: <AdminIcon />,
  [AppRole.SPECIALIST]: <SpecialistIcon />,
  [AppRole.CONSULTANT]: <SupportIcon />,
  [AppRole.MANAGER]: <ManagerIcon />,
};
const roleVariantsColor = {
  [AppRole.SUPER_ADMIN]: css.orange,
  [AppRole.SUPERVISOR]: css.gray,
  [AppRole.SUPPORT]: css.green,
  [AppRole.DIRECTOR]: css.orange,
  [AppRole.SPECIALIST]: css.blue,
  [AppRole.CONSULTANT]: css.green,
  [AppRole.MANAGER]: css.blue,
};

const TableRoleComponent = ({ type }: TableStatus) => {
  const { t } = useTranslation();

  return (
    type && (
      <Box
        direction="row"
        justifyContent="start"
        alignItems="center"
        className={css.container}
        fullWidth
      >
        <div className={roleVariantsColor[type]}>{roleVariantsIcon[type]}</div>
        <OverflowTooltip content={t(type)}>
          <p className={css.text}>{t(type)}</p>
        </OverflowTooltip>
      </Box>
    )
  );
};

export default TableRoleComponent;
