import { Box } from "@shared/ui";
import { memo } from "react";

import { Menu } from "../menu";

interface TabletNavigationProps {
  onClose?: () => void;
}

const TabletNavigation = ({ onClose }: TabletNavigationProps) => {
  return (
    <Box>
      <Menu onClose={onClose} />
    </Box>
  );
};

export default memo(TabletNavigation);
