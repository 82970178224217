import { Breadcrumbs } from "@components/breadCrumbs";
import { ShopCardInformation } from "@pages/shop/components/shopCardInformation";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { BreadcrumbPrefix } from "@shared/constants/breadcrumbs.constant.ts";
import { useUserRole } from "@shared/lib/hooks/useUser.ts";
import { useShopBannerQuery } from "@shared/services/shop";
import { Box, Tabs } from "@shared/ui";
import { Outlet, useParams } from "react-router-dom";

import { useLocationItems } from "./layout.hook.tsx";
import css from "./layout.module.scss";

export const Layout = () => {
  const role = useUserRole();

  const { clientId, locationId } = useParams();

  const { items } = useLocationItems({ clientId, locationId });
  const ignoredSegments = role === AppRole.DIRECTOR ? [0, 1, 3] : [1, 3];
  const { data, isLoading: isLoadingBanner } = useShopBannerQuery({ id: Number(locationId) });

  return (
    <Box className={css.container}>
      <Breadcrumbs ignoredSegments={ignoredSegments} prefix={BreadcrumbPrefix.SHOP} />
      <Tabs items={items} />{" "}
      <ShopCardInformation
        shopId={Number(locationId)}
        person={data}
        retailId={Number(clientId)}
        isLoading={isLoadingBanner}
      />
      <Outlet />
    </Box>
  );
};
