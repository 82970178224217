import { ControlledMultiSelectProps } from "@shared/ui";
import { lazy, Suspense } from "react";
import { FieldValues } from "react-hook-form";

const LazyControlledMultiSelect = lazy(() => import("./ControlledMultiSelect.component.tsx"));

export const ControlledMultiSelect = <T extends FieldValues>(
  props: ControlledMultiSelectProps<T>,
) => {
  return (
    <Suspense fallback={<div />}>
      <LazyControlledMultiSelect {...props} />
    </Suspense>
  );
};
