import AnalyticsIcon from "@shared/assets/icons/navigation/analytics.svg";
import ShopsIcon from "@shared/assets/icons/navigation/directorShops.svg";
import DocumentationIcon from "@shared/assets/icons/navigation/documentation.svg";
import RolesIcon from "@shared/assets/icons/navigation/roles.svg";
import UboProfileIcon from "@shared/assets/icons/navigation/ubo.svg";
import UsersIcon from "@shared/assets/icons/navigation/users.svg";
import RequestsIcon from "@shared/assets/icons/navigation/vocabulary.svg";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { useTypedSelector } from "@shared/lib/hooks/useTypedSelector";
import { useTranslation } from "react-i18next";

import { Item } from "./Navigation.types.ts";

export const useSidebarLinks = () => {
  const { t } = useTranslation();
  const retailId = useTypedSelector((state) => state.retail?.retailId);

  const items: Item[] = [
    {
      title: t("requests.heading"),
      link: AppRoutes.REQUESTS,
      roles: [AppRole.SUPERVISOR, AppRole.SUPER_ADMIN, AppRole.SPECIALIST],
      icon: <RequestsIcon />,
    },
    {
      title: t("clients"),
      link: AppRoutes.CLIENTS,
      roles: [AppRole.SUPERVISOR, AppRole.SUPER_ADMIN, AppRole.SPECIALIST],
      icon: <UsersIcon />,
    },
    {
      title: t("shops"),
      link: AppRoutes.RETAIL_DIRECTOR.replace(":id", String(retailId)),
      icon: <ShopsIcon />,
      roles: [AppRole.DIRECTOR],
    },
    {
      title: t("profiles-ubo"),
      link: AppRoutes.CUSTOMERS,
      icon: <UboProfileIcon />,
      roles: [AppRole.SUPERVISOR, AppRole.DIRECTOR, AppRole.SPECIALIST],
    },
    {
      title: t("page-analytics.heading"),
      link: retailId
        ? AppRoutes.ANALYTICS_GENERAL_INFORMATION.replace(":clientId", String(retailId))
        : AppRoutes.ANALYTICS,
      icon: <AnalyticsIcon />,
      roles: [AppRole.SUPERVISOR, AppRole.DIRECTOR, AppRole.SPECIALIST],
    },
    {
      title: t("roles.heading"),
      link: AppRoutes.ROLES,
      icon: <RolesIcon />,
      roles: [AppRole.SUPERVISOR, AppRole.SUPER_ADMIN],
    },
    {
      title: t("documentation.heading"),
      link: AppRoutes.DOCUMENTATION,
      icon: <DocumentationIcon />,
      roles: [
        AppRole.SUPERVISOR,
        AppRole.SUPER_ADMIN,
        AppRole.SPECIALIST,
        AppRole.SUPPORT,
        AppRole.DIRECTOR,
      ],
    },
  ];

  return items;
};
