import { CardInformation, CardInformationProps } from "@components/cardInformation";
import { useEditClientModal } from "@components/modals/client";
import { FirebaseEvent } from "@shared/constants/firebase/firebaseEvent.constant.ts";
import { useFirebase } from "@shared/lib/hooks/useFirebase.ts";
import { useTranslation } from "react-i18next";

interface RetailCardInformationProps extends Omit<CardInformationProps, "onEdit" | "type"> {
  retailId: number;
}

const RetailCardInformationComponent = ({
  retailId,
  person,
  ...props
}: RetailCardInformationProps) => {
  const { t } = useTranslation();
  const { logEventToFirebase } = useFirebase();
  const [openEditModal] = useEditClientModal();

  const onEdit = () => {
    openEditModal({ clientId: retailId, title: t("modals.edit.company") });
    logEventToFirebase(FirebaseEvent.RETAILS_EDIT);
  };

  return <CardInformation onEdit={onEdit} type="retail" person={person} {...props} />;
};

export default RetailCardInformationComponent;
