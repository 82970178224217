import { ControlledSelectProps } from "@shared/ui";
import { lazy, Suspense } from "react";
import { FieldValues } from "react-hook-form";

const LazyControlledSelect = lazy(() => import("./ControlledSelect.component"));

export const ControlledSelect = <T extends FieldValues, J extends string | number>(
  props: ControlledSelectProps<T, J>,
) => {
  return (
    <Suspense fallback={<div />}>
      <LazyControlledSelect {...props} />
    </Suspense>
  );
};
