import { useIsSidebarOpen } from "@shared/store/sidebar";
import { Box } from "@shared/ui";
import cn from "classnames";
import { memo } from "react";

import { LogoSection } from "../logoSection";
import { Menu } from "../menu";
import css from "./DesktopNavigation.module.scss";

const DesktopNavigation = () => {
  const isOpen = useIsSidebarOpen();

  return (
    <aside id="sidebar" className={cn(css.navigation, { [css.closedNavigation]: !isOpen })}>
      <LogoSection />
      <Box className={cn(css.menu, { [css.closedMenu]: !isOpen })}>
        <Menu />
      </Box>
      <Box
        direction={isOpen ? "row" : "column"}
        justifyContent="center"
        alignItems="center"
        fullWidth
        className={css.rules}
      >
        {isOpen && <>ⓒ 2024 UBO</>}
        {!isOpen && (
          <>
            UBO <br /> ⓒ 2024
          </>
        )}
      </Box>
    </aside>
  );
};

export default memo(DesktopNavigation);
