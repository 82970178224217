import { ExportReport } from "@components/exportStatistics";
import { CardSkeleton } from "@components/skeleton";
import PencilIcon from "@shared/assets/icons/actions/pencil.svg";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { useMediaQuery } from "@shared/lib/hooks/useMediaQuery.ts";
import { useCurrentRole } from "@shared/lib/hooks/useUser";
import { BannerInfo } from "@shared/services/clients";
import { StatusClient } from "@shared/types/statuses.interface.ts";
import { Box, EntityAvatar, LayoutTheme, TableStatus, Typography } from "@shared/ui";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import css from "./CardInformation.module.scss";

export interface CardInformationProps {
  person?: BannerInfo;
  shopId?: number;
  retailId?: number;
  type: "shop" | "retail";
  isLoading: boolean;
  onEdit: () => void;
}

const CardInformation = ({ type, person, isLoading, shopId, onEdit }: CardInformationProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("Mobile");

  const isDirector = useCurrentRole(AppRole.DIRECTOR);
  const isShowExportReport = isDirector && type === "shop";
  const isShop = Boolean(shopId);

  if (isLoading) {
    return <CardSkeleton />;
  }

  return (
    <LayoutTheme
      direction="row"
      alignItems="center"
      justifyContent="between"
      className={css.wrapper}
    >
      <Box direction="row" alignItems="center" className={css.cardContainer}>
        <Box direction="row" alignItems="center" className={css.companyInfo}>
          <Box
            justifyContent="center"
            alignItems="center"
            className={cn(css.imageContainer, {
              [css.imageNotFound]: !person?.logo,
            })}
          >
            <EntityAvatar
              id={shopId}
              companyName={person?.name}
              logo={person?.logo}
              className={cn(css.image)}
              size="h1"
              isBorder={isShop}
              isContain
              type={isShop ? "withBorder" : "default"}
              isPerson={false}
            />
          </Box>
          <Box className="gap-2">
            <Typography variant="h1" fontWeight="bold">
              {person?.name}
            </Typography>
            <Typography variant="body4" color="secondary">
              {isShop && person?.address}
            </Typography>
          </Box>
        </Box>
        {person?.status && !isDirector && (
          <TableStatus
            status={StatusClient[person.status]}
            sizeType="secondary"
            fullWidth={isMobile}
          />
        )}
      </Box>
      <Box
        direction="row"
        className={isDirector ? css.personInfoContainerDirector : css.personInfoContainer}
      >
        <Box className={isDirector ? css.personInfoForDirector : css.personInfo}>
          <Box>
            <Typography variant="body4bold" fontWeight="bold">
              {t(`card-${type}-label`)}
            </Typography>
            <Typography variant="body3" color="secondary" className={css.text}>
              {person?.contact_name}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body4bold" className={css.text} fontWeight="bold">
              {t(`contact-number-${type}`)}
            </Typography>
            <Typography variant="body3" color="secondary" className={css.text}>
              {person?.contact_number}
            </Typography>
          </Box>
        </Box>
        {!isDirector && (
          <Box className={css.svgContainer} onClick={onEdit}>
            <PencilIcon className={css.svg} />
          </Box>
        )}
        {isShowExportReport && <ExportReport className={css.report} />}
      </Box>
    </LayoutTheme>
  );
};

export default CardInformation;
