import { Breadcrumbs } from "@components/breadCrumbs";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { BreadcrumbPrefix } from "@shared/constants/breadcrumbs.constant.ts";
import { useCurrentRole } from "@shared/lib/hooks/useUser.ts";
import { useRetailBannerQuery } from "@shared/services/retail";
import { Box, Tabs } from "@shared/ui";
import { Outlet, useParams } from "react-router-dom";

import { RetailCardInformation } from "./components/retailCardInformation";
import { useShopsLayout } from "./layout.hook.tsx";
import css from "./layout.module.scss";

export const Layout = () => {
  const { clientId } = useParams();
  const isDirector = useCurrentRole(AppRole.DIRECTOR);

  const { isLoading, data } = useRetailBannerQuery(Number(clientId));
  const { items } = useShopsLayout({ clientId, isDirector });
  const ignoredSegments = isDirector ? [0, 1, 3] : [1, 3];

  return (
    <Box className={css.container}>
      <Breadcrumbs ignoredSegments={ignoredSegments} prefix={BreadcrumbPrefix.RETAIL} />
      <Tabs items={items} />
      <RetailCardInformation retailId={Number(clientId)} person={data} isLoading={isLoading} />
      <Outlet context={[data?.director_id, data?.name]} />
    </Box>
  );
};
