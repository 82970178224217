import BellIcon from "@shared/assets/icons/actions/bells/bell.svg";
import InfoIcon from "@shared/assets/icons/actions/info.svg";
import PencilIcon from "@shared/assets/icons/actions/pencil.svg";
import TrashIcon from "@shared/assets/icons/actions/trash.svg";
import { memo } from "react";

import { Box } from "../../../box";
import css from "./TableActions.module.scss";
import { TableActionsProps, TabletActionByKey } from "./tableActions.types.ts";

export type TableActionKey = keyof typeof TableActions;

const tableActionData: Record<TableActionKey, TabletActionByKey> = {
  infoHandler: { icon: <InfoIcon className={css.svg} />, label: "info" },
  editHandler: { icon: <PencilIcon className={css.svg} />, label: "edit" },
  removeHandler: { icon: <TrashIcon className={css.svg} />, label: "remove" },
  bellHandler: { icon: <BellIcon className={css.bellSvg} />, label: "remove" },
};

const TableActions = ({ isStart = false, ...props }: TableActionsProps) => {
  return (
    <Box
      direction="row"
      alignItems="center"
      justifyContent={isStart ? "start" : "end"}
      className={css.container}
    >
      {Object.entries(props).map(([key, handle]) => {
        if (typeof handle !== "function") {
          return null;
        }
        const { icon, label } = tableActionData[key as TableActionKey];

        return (
          <button key={key} aria-label={label} onClick={handle}>
            {icon}
          </button>
        );
      })}
    </Box>
  );
};

export default memo(TableActions);
