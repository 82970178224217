import { WITHOUT_CACHE } from "@shared/constants/apiCacheTime.constant.ts";
import { QueryTag } from "@shared/constants/queryTags.constants.ts";
import { Shop } from "@shared/services/shop";
import { ResponseWithPagination } from "@shared/types/global.interface.ts";
import { AccordionProps, SelectMultiOption } from "@shared/ui";
import { CustomToastVariants, showToast } from "@shared/ui/toast";
import { groupBy } from "@shared/utils/groupBy.ts";
import { parseError } from "@shared/utils/parseError.ts";
import { useQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { useEffect } from "react";

import { RetailService } from "./retail.services";
import { UseShopByIdQueryArgs, UseShopsListQueryArgs, UseShopsQueryArgs } from "./retail.types";

export const useRetailShopsQuery = ({
  pageSize,
  status,
  search,
  page,
  ordering,
  retailId,
}: UseShopsQueryArgs) => {
  const { data, isError, error, ...query } = useQuery({
    queryKey: [QueryTag.SHOPS, retailId, page, pageSize, status, search, ordering],
    queryFn: () =>
      RetailService.getRetailShops({
        page_size: pageSize,
        page,
        status,
        search,
        ordering,
        retailId,
      }),
    select: ({ data }): ResponseWithPagination<Shop> => data,
  });

  useEffect(() => {
    if (isError) {
      parseError(error, {
        handleCustomMessage: () =>
          showToast(t("toast.error.requests-add"), CustomToastVariants.ERROR),
      });
    }
  }, [isError, error]);

  return {
    ...query,
    shops: data?.results ?? [],
    pagination: {
      quantityPage: data?.total_pages,
      quantityItems: data?.count,
      length: data?.results.length,
      limit: pageSize,
      currentPage: page,
    },
  };
};
export const useRetailShopByIdQuery = ({ id }: UseShopByIdQueryArgs) => {
  const { data, isError, error, ...query } = useQuery({
    queryKey: [QueryTag.GET_SHOP_BY_ID, id],
    queryFn: () => RetailService.getRetailShopById(id),
    select: (response): Shop => response.data,
  });

  useEffect(() => {
    if (isError) {
      parseError(error, {
        handleCustomMessage: () => showToast(t("toast.error.user"), CustomToastVariants.ERROR),
      });
    }
  }, [isError, error]);

  return {
    ...query,
    data,
  };
};

export const useRetailBannerQuery = (retailId: number) => {
  const { data, isError, error, ...query } = useQuery({
    queryKey: [QueryTag.GET_RETAIL_BANNER_INFO, retailId],
    queryFn: () => {
      if (retailId) {
        return RetailService.getRetailBannerInfo(retailId);
      }
    },
    select: (response) => response?.data,
    enabled: Boolean(retailId),
  });

  useEffect(() => {
    if (isError) {
      parseError(error, {
        handleCustomMessage: () =>
          showToast(t("toast.error.retail-banner"), CustomToastVariants.ERROR),
      });
    }
  }, [isError, error]);

  return {
    ...query,
    data,
  };
};

export const useShopsListQuery = ({
  retailId,
  shopId,
  search,
  isFilterStatus = true,
}: UseShopsListQueryArgs) => {
  const { data, ...query } = useQuery({
    queryKey: [QueryTag.GET_SHOPS_LIST, retailId, shopId, search],
    queryFn: () => RetailService.getShopsList(retailId, search, isFilterStatus),
    select: ({ data }) => data?.results,
    staleTime: WITHOUT_CACHE,
  });

  const selectShops = data?.map(
    (shop): SelectMultiOption => ({
      label: shop.point_name,
      value: String(shop.id),
    }),
  );

  const availableShop = selectShops?.some((option) => Number(option.value) === shopId)
    ? [Number(shopId)]
    : [];

  const groupedShops = groupBy(data ?? [], ({ city }) => city);

  const shopsAccordionList: AccordionProps[] = Object.entries(groupedShops).map(
    ([city, shopsInCity]) => ({
      title: city,
      value: city,
      nested: shopsInCity.map((shop: Shop) => ({
        title: shop.point_name,
        value: shop.id,
        date: shop.registered_at,
      })),
    }),
  );

  return {
    ...query,
    shopsList: selectShops ?? [],
    availableShop,
    shopsAccordionList: data ? shopsAccordionList : [],
    maxLength:
      data && shopsAccordionList.reduce((sum, { nested }) => (sum += nested?.length ?? 0), 0),
  };
};

export const useShopsListForRequestsQuery = ({ retailId }: UseShopsListQueryArgs) => {
  const { data, ...query } = useQuery({
    queryKey: [QueryTag.GET_SHOPS_LIST, retailId],
    queryFn: () => RetailService.getShopsList(retailId),
    select: ({ data }) =>
      data?.results?.map((shop) => ({
        label: String(shop.point_name),
        value: String(shop.id),
        phone: shop.contact_number,
        address: shop.address,
      })),
    staleTime: WITHOUT_CACHE,
    enabled: Boolean(retailId),
  });

  return {
    ...query,
    shopsList: data ?? [],
  };
};
