import { QueryTag } from "@shared/constants/queryTags.constants.ts";
import { CustomToastVariants, showToast } from "@shared/ui/toast";
import { formatRangeDate } from "@shared/utils/date/formatRangeDate.ts";
import { parseError } from "@shared/utils/parseError.ts";
import { useQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { useEffect } from "react";

import { ShopService } from "./shop.service";
import { UseShopBannerQueryArgs, UseVisitsQueryArgs } from "./shop.types.ts";

export const useShopVisitsQuery = ({
  pageSize,
  search,
  range,
  page,
  ordering,
  id,
}: UseVisitsQueryArgs) => {
  const { data, isError, error, ...query } = useQuery({
    queryKey: [QueryTag.GET_VISITS_IN_SHOP, id, page, pageSize, search, ordering, range],
    queryFn: async () => {
      const dateFrom = await formatRangeDate(range.startDate);
      const dateTo = await formatRangeDate(range.endDate);

      return ShopService.getShopVisits({
        id,
        ordering,
        search,
        page,
        date_from: dateFrom,
        date_to: dateTo,
        page_size: pageSize,
      });
    },
    select: ({ data }) => data,
  });

  useEffect(() => {
    if (isError) {
      parseError(error, {
        handleCustomMessage: () => showToast(t("toast.error.visits"), CustomToastVariants.ERROR),
      });
    }
  }, [isError, error]);

  return {
    ...query,
    visits: data?.results ?? [],
    pagination: {
      quantityPage: data?.total_pages,
      quantityItems: data?.count,
      length: data?.results.length,
      limit: pageSize,
      currentPage: page,
    },
  };
};

export const useShopBannerQuery = ({ id }: UseShopBannerQueryArgs) => {
  const { data, isError, error, ...query } = useQuery({
    queryKey: [QueryTag.GET_SHOP_BANNER_INFO, id],
    queryFn: () => ShopService.getShopBannerInfo(id),
    select: (response) => response?.data,
  });
  useEffect(() => {
    if (isError) {
      parseError(error, {
        handleCustomMessage: () =>
          showToast(t("toast.error.shop-banner"), CustomToastVariants.ERROR),
      });
    }
  }, [isError, error]);

  return {
    ...query,
    data,
  };
};
