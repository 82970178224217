import { memo } from "react";
import { Link } from "react-router-dom";

import { Box } from "../../../box";
import css from "./TableAvatar.module.scss";
import { TableAvatarProps } from "./TableAvatar.types.ts";
import { TableAvatarContent } from "./TableAvatarContent.component.tsx";

const TableAvatarComponent = (data: TableAvatarProps) => {
  if (data.link) {
    return (
      <Link to={data.link || ""} onClick={data.onClick} className={css.link}>
        <TableAvatarContent {...data} />
      </Link>
    );
  }

  return (
    <Box direction="row" className={css.link}>
      <TableAvatarContent {...data} />
    </Box>
  );
};

export default memo(TableAvatarComponent);
