export const BreadcrumbsConstant = {
  retail_persons: "retail-persons",
  retail_locations: "locations",
  "retail_employee-statistics": "employee-statistics",
  retail_equipments: "equipment-locations",
  retail_clients: "clients",
  shop_persons: "shop-persons",
  shop_statistic: "statistic",
  shop_locations: "locations",
  "shop_employee-statistics": "employee-statistics",
  shop_equipment: "equipment-location",
  shop_clients: "clients",
  locations: "locations",
  persons: "shop-persons",
  customers: "customers",
  analytics: "analytics",
  clients: "clients",
} as const;

export enum BreadcrumbPrefix {
  RETAIL = "retail_",
  SHOP = "shop_",
  DEFAULT = "",
}
