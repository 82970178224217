import { HeaderLayout } from "@components/header";
import { Advertising } from "@pages/advertising";
import * as Analytics from "@pages/analytics";
import * as Authorization from "@pages/authorization";
import { Clients } from "@pages/clients";
import { Customer } from "@pages/customer/index.ts";
import { Customers } from "@pages/customers/index.ts";
import { Documentation } from "@pages/documentation";
import { Employee } from "@pages/employeeProfile/index.tsx";
import * as Errors from "@pages/errors";
import { Profile } from "@pages/profile";
import { Requests } from "@pages/requests";
import * as Retail from "@pages/retail";
import { Roles } from "@pages/roles";
import * as Location from "@pages/shop";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { useBaseUrl } from "@shared/lib/hooks/useUser.ts";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";

import { CheckRole } from "./AuthProvider/CheckRole.tsx";
import { ProtectedRoute } from "./AuthProvider/ProtectedRoute.tsx";

export const Router = () => {
  const baseUrl = useBaseUrl();
  const { t } = useTranslation();

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path={AppRoutes.HOME} element={<HeaderLayout />}>
          <Route
            element={
              <CheckRole
                allowedRoles={[
                  AppRole.DIRECTOR,
                  AppRole.SUPER_ADMIN,
                  AppRole.SUPERVISOR,
                  AppRole.SPECIALIST,
                ]}
              />
            }
          >
            <Route path={AppRoutes.CLIENT_DETAIL} element={<Retail.Layout />}>
              <Route path={AppRoutes.LOCATIONS} element={<Retail.Shops />} />
            </Route>
            <Route path={AppRoutes.DETAIL_LOCATIONS} element={<Location.Layout />}>
              <Route path={AppRoutes.STATISTIC} element={<Location.Statistics />} />
            </Route>
            <Route path={AppRoutes.EMPLOYEE_RETAIL} element={<Employee />} />
            <Route path={AppRoutes.EMPLOYEE_SHOP} element={<Employee />} />
          </Route>
          <Route
            element={
              <CheckRole
                allowedRoles={[
                  AppRole.SUPER_ADMIN,
                  AppRole.SUPERVISOR,
                  AppRole.SPECIALIST,
                  AppRole.SUPPORT,
                  AppRole.DIRECTOR,
                ]}
              />
            }
          >
            <Route path={AppRoutes.DOCUMENTATION} element={<Documentation />} />
            <Route path={AppRoutes.PROFILE} element={<Profile />} />
          </Route>
          <Route element={<CheckRole allowedRoles={[AppRole.SUPER_ADMIN, AppRole.SUPERVISOR]} />}>
            <Route path={AppRoutes.ADVERTISING} element={<Advertising />} />
          </Route>
          <Route
            element={
              <CheckRole
                allowedRoles={[
                  AppRole.SUPER_ADMIN,
                  AppRole.SUPERVISOR,
                  AppRole.SPECIALIST,
                  AppRole.DIRECTOR,
                ]}
              />
            }
          >
            <Route path={AppRoutes.CLIENT_DETAIL} element={<Retail.Layout />}>
              <Route path={AppRoutes.LOCATIONS} element={<Retail.Shops />} />
              <Route path={AppRoutes.EQUIPMENTS} element={<Retail.Equipment />} />
              {/* <Route path={AppRoutes.STATISTICS} element={<Retail.Statistics />} /> */}
              <Route path={AppRoutes.PERSONS} element={<Retail.Persons />} />
              <Route path={AppRoutes.EMPLOYEE_STATISTICS} element={<Retail.EmployeeStatistics />} />
            </Route>
            <Route path={AppRoutes.DETAIL_LOCATIONS} element={<Location.Layout />}>
              <Route path={AppRoutes.PERSONS} element={<Location.Persons />} />
              <Route path={AppRoutes.EQUIPMENT} element={<Location.Equipment />} />
              <Route path={AppRoutes.STATISTIC} element={<Location.Statistics />} />
              <Route
                path={AppRoutes.EMPLOYEE_STATISTICS}
                element={<Location.EmployeeStatistics />}
              />
            </Route>
            <Route index path={AppRoutes.CLIENTS} element={<Clients meta={t("clients")} />} />
            <Route path={AppRoutes.REQUESTS} element={<Requests />} />
          </Route>
          <Route
            element={
              <CheckRole
                allowedRoles={[
                  AppRole.SUPER_ADMIN,
                  AppRole.SUPERVISOR,
                  AppRole.SPECIALIST,
                  AppRole.DIRECTOR,
                ]}
              />
            }
          >
            {/*<Route path={AppRoutes.FINANCE} element={<Finance.Layout />}>*/}
            {/*  <Route path={AppRoutes.ANALYTIC} element={<Finance.Analytics />} />*/}
            {/*  <Route path={AppRoutes.FINANCE_STATISTIC} element={<Finance.Statistics />} />*/}
            {/*  <Route path={AppRoutes.CONVERSION} element={<Finance.Conversion />} />*/}
            {/*  <Route path={AppRoutes.BILLS} element={<Finance.Bills />} />*/}
            {/*  <Route path={AppRoutes.BILL_DETAIL} element={<Finance.CurrentBill />} />*/}
            {/*</Route>*/}
            <Route path={AppRoutes.CUSTOMERS} element={<Customers />} />
            <Route path={AppRoutes.CUSTOMER} element={<Customer />} />
          </Route>
          <Route element={<CheckRole allowedRoles={[AppRole.SUPER_ADMIN, AppRole.SUPERVISOR]} />}>
            <Route path={AppRoutes.ROLES} element={<Roles />} />
          </Route>
          <Route
            element={
              <CheckRole
                allowedRoles={[
                  AppRole.SUPER_ADMIN,
                  AppRole.SUPERVISOR,
                  AppRole.SPECIALIST,
                  AppRole.DIRECTOR,
                ]}
              />
            }
          >
            <Route
              path={AppRoutes.ANALYTICS}
              element={<Clients isAnalytics meta={t("page-analytics.meta")} />}
            />
            <Route path={AppRoutes.DETAIL_ANALYTICS} element={<Analytics.Layout />}>
              <Route
                path={AppRoutes.ANALYTICS_GENERAL_INFORMATION}
                element={<Analytics.GeneralInformation />}
              />
            </Route>
          </Route>
          <Route path={AppRoutes.HOME} element={<Navigate to={baseUrl} replace />} />
        </Route>
      </Route>
      <Route element={<ProtectedRoute isNonePrivate />}>
        <Route path={AppRoutes.LOGIN} element={<Authorization.Login />} />
        <Route path={AppRoutes.FORGOT_PASSWORD} element={<Authorization.ForgotPassword />} />
      </Route>
      <Route path={AppRoutes.SERVER_ERROR} element={<Errors._500 />} />
      <Route path={AppRoutes.OTHER} element={<Errors._404 />} />
    </Routes>
  );
};
