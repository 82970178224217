import EmployeeStatisticsIcon from "@shared/assets/icons/pageList/employeeStatistic.svg";
import EquipmentIcon from "@shared/assets/icons/pageList/equipments.svg";
import PersonsIcon from "@shared/assets/icons/pageList/persons.svg";
import StatisticsIcon from "@shared/assets/icons/pageList/statistics.svg";
import { AppRole } from "@shared/constants/appRoles.constant";
import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { useCurrentRole } from "@shared/lib/hooks/useUser";
import { useTranslation } from "react-i18next";

interface LocationItemsProps {
  clientId?: number | string;
  locationId?: number | string;
}

export const useLocationItems = ({ clientId, locationId }: LocationItemsProps) => {
  const { t } = useTranslation();
  const isDirector = useCurrentRole(AppRole.DIRECTOR);

  const items = [
    {
      title: t("statistics-location"),
      link: `${AppRoutes.CLIENTS}/${clientId}/${AppRoutes.LOCATIONS}/${locationId}/${AppRoutes.STATISTIC}`,
      icon: <StatisticsIcon />,
    },
    {
      title: t("shop-persons"),
      link: `${AppRoutes.CLIENTS}/${clientId}/${AppRoutes.LOCATIONS}/${locationId}/${AppRoutes.PERSONS}`,
      icon: <PersonsIcon />,
    },
    {
      title: t("employee-statistics-titles.title"),
      link: `${AppRoutes.CLIENTS}/${clientId}/${AppRoutes.LOCATIONS}/${locationId}/${AppRoutes.EMPLOYEE_STATISTICS}`,
      icon: <EmployeeStatisticsIcon />,
    },
  ];

  if (!isDirector) {
    items.push({
      title: t("equipment-location"),
      link: `${AppRoutes.CLIENTS}/${clientId}/${AppRoutes.LOCATIONS}/${locationId}/${AppRoutes.EQUIPMENT}`,
      icon: <EquipmentIcon />,
    });
  }

  return {
    items,
  };
};
