export enum AppRoutes {
  HOME = "/",
  PROFILE = "/profile",
  DOCUMENTATION = "/documentation",
  ADVERTISING = "/advertising",
  CLIENTS = "/clients",
  REQUESTS = "/requests/",
  SHOPS = "/clients/:clientId/locations",
  SHOP = "/clients/:clientId/locations/:locationId/statistic",
  SHOP_STATISTIC = ":locationId/statistic",
  CLIENT_DETAIL = "clients/:clientId",
  LOCATIONS = "locations",
  EQUIPMENTS = "equipments",
  EQUIPMENT = "equipment",
  STATISTICS = "statistics",
  EMPLOYEE_STATISTICS = "employee-statistics",
  STATISTIC = "statistic",
  RETAIL_DIRECTOR = "clients/:id/locations",
  PERSONS = "persons",
  DETAIL_LOCATIONS = "clients/:clientId/locations/:locationId",
  EMPLOYEE_RETAIL = "/clients/:retailId/persons/:id",
  EMPLOYEE_SHOP = "/clients/:retailId/locations/:locationId/persons/:id",
  FINANCE = "/finance/",
  ANALYTICS = "/analytics",
  DETAIL_ANALYTICS = "/analytics/:clientId",
  ANALYTICS_GENERAL_INFORMATION = "/analytics/:clientId/general-information",
  FINANCE_STATISTIC = "statistic-finance",
  CONVERSION = "conversion",
  BILLS = "bills",
  BILL_DETAIL = "bills/:billId",
  FINANCE_BILLS = "/finance/bills",
  ROLES = "/roles",
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot-password",
  SERVER_ERROR = "/503",
  CLIENT_ERROR = "/404",
  UBO = "/ubo",
  UBO_PROFILE = "/ubo/profiles-ubo/:profileId",
  UBO_PROFILES = "/ubo/profiles-ubo",
  CUSTOMERS = "/customers",
  CUSTOMER = "/customers/:id",
  OTHER = "*",
}
