import { TableProvider } from "@app/providers/TableProvider/TableProvider.tsx";
import { TableProps } from "@shared/ui/table/Table.types.ts";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import cn from "classnames";
import { memo } from "react";

import css from "./Table.module.scss";

const TableComponent = <T, J>({
  className = "",
  data,
  columns,
  onSortingChange,
  orderBy,
  children,
  isScroll,
  ...props
}: TableProps<T, J>) => {
  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: onSortingChange,
    state: {
      sorting: orderBy,
    },
    enableSortingRemoval: false,
    debugTable: IS_DEV === "true",
    defaultColumn: {
      minSize: 100,
    },
  });

  const { getRowModel, getFlatHeaders } = table;

  const headers = getFlatHeaders();
  const rows = getRowModel().rows;

  return (
    <table
      className={cn(className, css.table, {
        [css.isScroll]: isScroll,
      })}
    >
      <TableProvider headers={headers} rows={rows} {...props}>
        {children}
      </TableProvider>
    </table>
  );
};

export default memo(TableComponent) as typeof TableComponent;
