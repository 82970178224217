import CashRegisterIcon from "@shared/assets/icons/equipment/cashRegister.svg";
import CctvIcon from "@shared/assets/icons/equipment/cctv.svg";
import CloudIcon from "@shared/assets/icons/equipment/cloud.svg";
import BaseIcon from "@shared/assets/icons/equipment/database.svg";
import HelicopterIcon from "@shared/assets/icons/equipment/helicopter.svg";
import TabletIcon from "@shared/assets/icons/equipment/tablet.svg";
import { TitleType } from "@shared/types/statuses.interface.ts";

import { Box } from "../../../box";
import { OverflowTooltip } from "../../../tooltip";
import { Typography } from "../../../typography";
import css from "./TableAvatarEquipment.module.scss";

const variantsEquipment = {
  [TitleType.Camera]: <CctvIcon className={css.icon} />,
  [TitleType.Tablet]: <TabletIcon className={css.icon} />,
  [TitleType.Software]: <CloudIcon className={css.icon} />,
  [TitleType.Server]: <BaseIcon className={css.icon} />,
  [TitleType.CashRegister]: <CashRegisterIcon className={css.icon} />,
  [TitleType.Other]: <HelicopterIcon className={css.icon} />,
};

interface TableAvatarEquipmentProps {
  name: string;
  type: TitleType;
  ip: string;
  onClick?: () => void;
}

const TableAvatarEquipment = ({ name, type, ip, onClick }: TableAvatarEquipmentProps) => {
  const subTitle = `IP ${ip}`;
  return (
    <Box direction="row" className={css.container} onClick={onClick}>
      <Box>{variantsEquipment[type]}</Box>
      <Box className={css.titlesContainer} truncate>
        <OverflowTooltip content={name}>
          <Typography variant="body2bold" fontWeight="bold" className={css.title} truncate>
            {name}
          </Typography>
        </OverflowTooltip>
        {ip && (
          <OverflowTooltip content={subTitle}>
            <Typography variant="body4" color="secondary" className={css.subTitle} truncate>
              {subTitle}
            </Typography>
          </OverflowTooltip>
        )}
      </Box>
    </Box>
  );
};

export default TableAvatarEquipment;
