import { BreadcrumbPrefix, BreadcrumbsConstant } from "@shared/constants/breadcrumbs.constant.ts";
import { Box } from "@shared/ui";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import css from "./BreadCrumbs.module.scss";

interface BreadcrumbsProps {
  dataTestId?: string;
  ignoredSegments?: number[];
  lastSegmentName?: string;
  prefix?: BreadcrumbPrefix;
}

type BreadcrumbsType = keyof typeof BreadcrumbsConstant;

const Breadcrumbs = ({
  ignoredSegments,
  lastSegmentName,
  prefix = BreadcrumbPrefix.DEFAULT,
}: BreadcrumbsProps) => {
  const { t } = useTranslation();

  const location = useLocation();
  const segments = location.pathname.split("/").filter(Boolean);

  return (
    <Box direction="row" className={css.breadcrumbs}>
      {segments.map((path, index) => {
        if (ignoredSegments?.includes(index)) {
          return null;
        }
        const pathWithPrefix = BreadcrumbsConstant[`${prefix}${path}` as BreadcrumbsType];

        const str =
          index === segments.length - 1 && lastSegmentName
            ? lastSegmentName
            : decodeURIComponent(t(pathWithPrefix));

        return (
          <Box direction="row" key={index} className={css.crumb}>
            <Link to={`/${segments.slice(0, index + 1).join("/")}`}>{str}</Link>
            {index < segments.length - 1 && <span className={css.separator}>/</span>}
          </Box>
        );
      })}
    </Box>
  );
};
export default Breadcrumbs;
