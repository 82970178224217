import { api } from "@shared/configs/api.config.ts";
import { ApiUrl } from "@shared/constants/apiUrl.constant.ts";
import { QueryParams, ResponseWithBannerInfo } from "@shared/types/global.interface.ts";
import { parseObjToQueryParams } from "@shared/utils/parseObjToQueryParams.ts";

import { Client } from "./clients.types.ts";

export const ClientService = {
  async getClientsInfo(data: QueryParams) {
    const params = parseObjToQueryParams(data);

    return api.get<ResponseWithBannerInfo<Client[]>>(ApiUrl.RETAIL, {
      params,
    });
  },
  async getRetailListInfo() {
    return api.get<ResponseWithBannerInfo<Client[]>>(ApiUrl.RETAIL);
  },

  async getClientsInfoById(retailId: number) {
    return api.get<Client>(ApiUrl.RETAIL_BY_ID.replace(":id", String(retailId)));
  },

  async updateClientById(data: Partial<Client>, retailId: number) {
    return api.patch<Client>(ApiUrl.RETAIL_BY_ID.replace(":id", String(retailId)), data);
  },
};
