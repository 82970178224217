import EmployeeStatisticsIcon from "@shared/assets/icons/pageList/employeeStatistic.svg";
import EquipmentIcon from "@shared/assets/icons/pageList/equipments.svg";
import PersonsIcon from "@shared/assets/icons/pageList/persons.svg";
import LocationsIcon from "@shared/assets/icons/pageList/shops.svg";
// import StatisticsIcon from "@shared/assets/icons/pageList/statistics.svg";
import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { useTranslation } from "react-i18next";

interface ClientItemsProps {
  clientId?: string;
  isDirector?: boolean;
}

export const useShopsLayout = ({ clientId, isDirector = false }: ClientItemsProps) => {
  const { t } = useTranslation();

  const items = [
    {
      title: t("locations"),
      link: `${AppRoutes.CLIENTS}/${clientId}/${AppRoutes.LOCATIONS}`,
      icon: <LocationsIcon />,
    },
    // {
    //   title: t("statistics-locations"),
    //   link: `${AppRoutes.CLIENTS}/${clientId}/${AppRoutes.STATISTICS}`,
    //   icon: <StatisticsIcon />,
    // },
    {
      title: t("retail-persons"),
      link: `${AppRoutes.CLIENTS}/${clientId}/${AppRoutes.PERSONS}`,
      icon: <PersonsIcon />,
    },
    {
      title: t("employee-statistics-titles.title"),
      link: `${AppRoutes.CLIENTS}/${clientId}/${AppRoutes.EMPLOYEE_STATISTICS}`,
      icon: <EmployeeStatisticsIcon />,
    },
    {
      title: t("equipment-locations"),
      link: `${AppRoutes.CLIENTS}/${clientId}/${AppRoutes.EQUIPMENTS}`,
      icon: <EquipmentIcon />,
    },
  ];

  if (isDirector) {
    items.splice(items.length - 1, 1);
  }

  return {
    items,
  };
};
