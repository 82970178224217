import cn from "classnames";
import { ForwardedRef, forwardRef, memo, SyntheticEvent, useState } from "react";

import ErrorIcon from "../../assets/icons/notFound/imageNotFound.png";
import { getImage } from "./getImage.ts";
import css from "./Image.module.scss";
import { ImageProps } from "./Image.types.ts";

export const Image = memo(
  forwardRef(
    ({ src = "", className, alt, ...props }: ImageProps, ref: ForwardedRef<HTMLImageElement>) => {
      const [isLoading, setIsLoading] = useState(true);

      const handleError = (e: SyntheticEvent<HTMLImageElement>) => {
        e.currentTarget.src = ErrorIcon;
      };

      const handleLoad = () => {
        setIsLoading(false);
      };

      return (
        <>
          <img
            ref={ref}
            src={getImage(src)}
            className={cn(className, { [css.hiddenIcon]: isLoading })}
            alt={alt}
            onLoad={handleLoad}
            onError={handleError}
            {...props}
          />
          {isLoading && <div className={cn(css.skeleton, className)} />}
        </>
      );
    },
  ),
);

Image.displayName = "Image";
