import { api } from "@shared/configs/api.config.ts";
import { ApiUrl } from "@shared/constants/apiUrl.constant.ts";
import { User } from "@shared/services/users";
import { Tokens } from "@shared/store/user/user.types.ts";
import {
  removeTokensFromCookieStorage,
  saveTokensToCookieStorage,
  saveTokensToSessionStorage,
} from "@shared/utils/token.ts";

import { Login, ResetPassword } from "./auth.types.ts";

export const AuthService = {
  async login({ email, password, isChecked }: Login) {
    const response = await api.post<Tokens>(ApiUrl.LOGIN, {
      email,
      password,
    });
    const tokens = response.data;
    if (isChecked) {
      saveTokensToCookieStorage(tokens);
    } else {
      saveTokensToSessionStorage(tokens);
    }
  },

  async userMe() {
    const { data } = await api.get<User>(ApiUrl.USER_ME);

    return data;
  },

  async logout() {
    removeTokensFromCookieStorage();
  },

  async resetPassword({ email }: ResetPassword) {
    return api.post<ResetPassword>(ApiUrl.RESET_PASSWORD, {
      email,
    });
  },
};
