import * as Switch from "@radix-ui/react-switch";
import { SwitchProps } from "@radix-ui/react-switch";
import { forwardRef, ReactNode } from "react";

import css from "./Toggle.module.scss";

interface ToggleProps extends SwitchProps {
  children?: ReactNode;
}

const ToggleComponent = forwardRef<HTMLInputElement, ToggleProps>(
  ({ children, checked, ...props }, ref) => {
    return (
      <div className={css.wrapper}>
        <label className={css.label}>{children}</label>
        <Switch.Root className={css.root} checked={checked} {...props}>
          <Switch.Thumb
            role="switch"
            aria-checked={checked ? "true" : "false"}
            aria-label="switch"
            className={css.thumb}
            ref={ref}
          />
        </Switch.Root>
      </div>
    );
  },
);

export default ToggleComponent;
