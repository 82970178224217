import { useBurgerModal } from "@components/modals/burger";
import BurgerIcon from "@shared/assets/icons/actions/burgerMenu.svg";
import { useMediaQuery } from "@shared/lib/hooks/useMediaQuery.ts";
import { memo } from "react";

import { HeaderProps } from "../../Header.types.ts";
import { HeaderSection } from "./HeaderSection.component.tsx";

const BurgerMenu = ({ role }: HeaderProps) => {
  const isMobile = useMediaQuery("Mobile");
  const [openBurgerModal, closeBurgerModal] = useBurgerModal();

  const onOpen = () =>
    openBurgerModal({ isMobile, role, customHeader: <HeaderSection onClose={closeBurgerModal} /> });

  return <BurgerIcon className="svg-primary cursor-pointer" onClick={onOpen} />;
};

export default memo(BurgerMenu);
