import { TableCellProps } from "@shared/ui/table/Table.types.ts";
import cn from "classnames";
import { memo } from "react";

import css from "./Table.module.scss";

const TableCellComponent = ({
  className,
  children,
  isLast,
  isBorder = false,
  ...props
}: TableCellProps) => {
  return (
    <td
      className={cn(className, css.cell, {
        [css["tableRow-isLast"]]: isLast,
        [css.tableCellWithBorder]: isBorder,
        [css.tableCellWithoutBorder]: !isBorder,
      })}
      {...props}
    >
      {children}
    </td>
  );
};
export default memo(TableCellComponent);
