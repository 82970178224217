import { WITHOUT_CACHE } from "@shared/constants/apiCacheTime.constant.ts";
import { QueryTag } from "@shared/constants/queryTags.constants.ts";
import { FilterResult } from "@shared/lib/hooks/useFilters.ts";
import { ResponseWithBannerInfo } from "@shared/types/global.interface.ts";
import { CustomToastVariants, showToast } from "@shared/ui/toast";
import { parseError } from "@shared/utils/parseError.ts";
import { useQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { useEffect } from "react";

import { ClientService } from "./clients.services.ts";
import { Client } from "./clients.types.ts";

interface UseClientByIdQueryArgs {
  clientId: number;
}

export const useClientByIdQuery = ({ clientId }: UseClientByIdQueryArgs) => {
  const { data, isError, error, ...query } = useQuery({
    queryKey: [QueryTag.GET_CLIENTS_BY_ID, clientId],
    queryFn: () => ClientService.getClientsInfoById(clientId),
    select: (response) => response.data,
  });

  useEffect(() => {
    if (isError) {
      parseError(error, {
        handleCustomMessage: () => showToast(t("toast.error.user"), CustomToastVariants.ERROR),
      });
    }
  }, [isError, error]);

  return {
    ...query,
    data,
  };
};

export const useClientsQuery = ({ pageSize, status, search, page, ordering }: FilterResult) => {
  const { data, isError, error, ...query } = useQuery({
    queryKey: [QueryTag.GET_CLIENTS, pageSize, status, search, ordering, page],
    queryFn: () =>
      ClientService.getClientsInfo({
        page_size: pageSize,
        page,
        status,
        search,
        ordering,
      }),
    select: ({ data }): ResponseWithBannerInfo<Client[]> => data,
  });

  useEffect(() => {
    if (isError) {
      parseError(error, {
        handleCustomMessage: () => showToast(t("toast.error.clients"), CustomToastVariants.ERROR),
      });
    }
  }, [isError, error]);

  return {
    ...query,
    clients: data?.results ?? [],
    pagination: {
      quantityPage: data?.total_pages,
      quantityItems: data?.count,
      length: data?.results.length,
      limit: pageSize,
      currentPage: page,
    },
    statistics: {
      archived: data?.archived,
      disconnected: data?.disconnected,
      connected: data?.connected,
      on_service: data?.on_service,
    },
  };
};

interface useRetailListQuery {
  selectedRetailId?: number;
  enabled?: boolean;
}

export const useRetailListQuery = ({ selectedRetailId, enabled }: useRetailListQuery) => {
  const { data, ...query } = useQuery({
    queryKey: [QueryTag.GET_RETAILS_LIST],
    queryFn: () => ClientService.getRetailListInfo(),
    select: ({ data }) => data?.results,
    staleTime: WITHOUT_CACHE,
    enabled,
  });

  const retailList = data?.map((retail) => ({
    label: String(retail.retail_network_name),
    value: String(retail.id),
  }));

  const selectedRetailData = data?.find((retail) => retail.id === selectedRetailId);

  return {
    ...query,
    retailInfo: {
      name: selectedRetailData?.director?.user_info.name,
      surname: selectedRetailData?.director?.user_info.surname,
      email: selectedRetailData?.director?.user_info.email,
      phone: selectedRetailData?.director?.user_info.phone,
    },
    retailList: retailList ?? [],
  };
};
