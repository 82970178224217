import cn from "classnames";
import * as React from "react";

const variants = {
  h1: ["text-xl", "font-bold"],
  h1md: ["text-xl", "font-medium"],
  h2: ["text-lg", "font-bold"],
  body1: ["text-lg", "font-medium"],
  body2smd: ["text-base", "font-semibold"],
  body2bold: ["text-base", "font-bold"],
  body2: ["text-base"],
  body3smd: ["text-md", "font-semibold"],
  body3bold: ["text-md", "font-bold"],
  body3md: ["text-md", "font-medium"],
  body3: ["text-md", "font-normal"],
  body4bold: ["text-sm", "font-bold"],
  body4: ["text-sm", "font-normal"],
};

export type Variants = keyof typeof variants;

const getTag = (variant: string) => {
  if (variant.includes("body")) return "p";
  if (variant.includes("h1")) return "h1";
  if (variant.includes("h2")) return "h2";
  return variant;
};

const opacities = {
  "0": "opacity-0",
  "5": "opacity-5",
  "10": "opacity-10",
  "15": "opacity-15",
  "20": "opacity-20",
  "30": "opacity-30",
  "35": "opacity-35",
  "40": "opacity-40",
  "50": "opacity-50",
  "60": "opacity-60",
  "70": "opacity-70",
  "80": "opacity-80",
  "90": "opacity-90",
  "100": "opacity-100",
};
const fontWeights = {
  normal: "font-normal",
  medium: "font-medium",
  semibold: "font-semibold",
  bold: "font-bold",
};

const colors = {
  primary: "text-primary",
  secondary: "text-secondary-color",
  gray: "text-gray-color",
  red: "text-red",
  white: "text-white",
  purple: "text-purple",
  blue: "text-blue",
  orange: "text-orange",
  black: "text-black",
  error: "text-red",
};

const alignments = {
  center: "text-center",
  left: "text-left",
  right: "text-right",
  justify: "text-justify",
  start: "text-start",
  end: "text-end",
};

export type TypographyProps = Omit<React.HTMLProps<HTMLHeadingElement>, "ref"> & {
  variant?: Variants;
  opacity?: keyof typeof opacities;
  fontWeight?: keyof typeof fontWeights;
  color?: keyof typeof colors;
  alignment?: keyof typeof alignments;
  truncate?: boolean;
  select?: boolean;
  uppercase?: boolean;
};

export const Typography = React.forwardRef<HTMLHeadingElement, TypographyProps>(
  (
    {
      variant = "body1",
      alignment,
      opacity = "100",
      fontWeight = "normal",
      className,
      children,
      color = "primary",
      truncate,
      select = true,
      uppercase = false,
      ...props
    },
    ref,
  ) => {
    return React.createElement(
      getTag(variant as string),
      {
        className: cn(
          variants[variant] && [...variants[variant]],
          color && colors[color],
          opacity && opacities[opacity],
          alignment && alignments[alignment],
          truncate && "truncate",
          select && "select-text",
          uppercase && "uppercase",
          fontWeight && fontWeights[fontWeight],
          className,
        ),
        ref,
        ...props,
      },
      children,
    );
  },
);

Typography.displayName = "Typography";
