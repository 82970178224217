import { Regular } from "@shared/constants/regular.constant.ts";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Input } from "../input";

interface NameInputProps {
  name: string;
  title: string;
  placeholder: string;
  disabled?: boolean;
  className?: string;
}

const NameInputComponent = ({ name, title, placeholder, disabled, className }: NameInputProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field, fieldState: { error } }) => {
        return (
          <Input
            title={title}
            placeholder={placeholder}
            required
            error={error}
            autoComplete="off"
            className={className}
            disabled={disabled}
            {...field}
            onChange={(e) => {
              const value = e.target.value;
              if (Regular.SPECIAL_CHARACTERS.test(value[0])) {
                field.onChange("");
                return;
              }

              const words = value.split(" ");

              for (let i = 0; i < words.length; i++) {
                const word = words[i];
                if (word.length > 0) {
                  words[i] = word[0].toUpperCase() + word.slice(1).toLowerCase();
                }
              }

              field.onChange(words.join(" "));
            }}
          />
        );
      }}
    />
  );
};

export default memo(NameInputComponent);
