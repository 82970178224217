import { Box } from "../box";
import { Tab } from "./components";
import css from "./Tabs.module.scss";
import { TabsProps } from "./tabs.types";

const Tabs = ({ items }: TabsProps) => {
  return (
    <Box direction="row" alignItems="center" className={css.container} fullWidth>
      <ul className={css.tabs}>
        {items.map((props) => (
          <Tab key={props.link} {...props} />
        ))}
      </ul>
    </Box>
  );
};

export default Tabs;
