import { TableRowProps } from "@shared/ui/table/Table.types.ts";
import cn from "classnames";
import { memo } from "react";

import css from "./Table.module.scss";

const TableRowComponent = ({ className = "", children, onClick }: TableRowProps) => {
  return (
    <tr onClick={onClick} className={cn(className, css.tableRow)}>
      {children}
    </tr>
  );
};

export default memo(TableRowComponent);
